import React from 'react'
import PhysicianLandingPage from 'containers/physician-landing-page'

const DrYoungLandingPage = () => (
  <PhysicianLandingPage
    physician="Dr. Young"
    institution="Dr. Young"
    referralCode="DRYOUNG"
    physicianURL="https://stanfordhealthcare.org/doctors/y/stacy-young.html"
  />
)

export default DrYoungLandingPage
